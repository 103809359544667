import React from 'react'

const Social = () => {
  return (
   <div className="home__social">
    <a href="https://www.facebook.com/franck.anselme.9" className="home__social-icon" target='_blank'>
        <i className="bx bxl-facebook"></i>
    </a>
    <a href="https://x.com/Tsinjoniai8836" className="home__social-icon" target='_blank'>
        <i className="uil uil-twitter"></i>
    </a>
    <a href="https://github.com/franck504" className="home__social-icon" target='_blank'>
        <i className="uil uil-github-alt"></i>
    </a>
   </div>
  )
}

export default Social